<template>
    <div class="not_found">
        <p>404 | Page Not Found</p>
    </div>
</template>

<script>
export default {
    name: "404"
}
</script>

<style scoped>
    .not_found {
        background-color: var(--secondary-color);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        direction: ltr;
    }

    .not_found p {
        color: var(--primary-color);
    }
</style>